import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const AboutYouPage = props => (
  <Layout>
    <SEO title="What is True Wealth" />

    <BackgroundImage
      fluid={props.data.aboutYouImage.childImageSharp.fluid}
      alt="A man holding his two children whilst a third one plays"
    >
      <Header headerText="What is True Wealth?"></Header>
    </BackgroundImage>

    <Section>
      <p>Being ‘wealthy’ means different things to different people.</p>
      <p>
        For some clients it can refer to their money and assets but for others
        may mean financial freedom, having the means to fulfil life goals etc.
      </p>
      <p>
        {" "}
        We invest the time to explore what being ‘wealthy’ means to them with a
        view to enabling them to attain ‘True Wealth’.
      </p>

      <TwmButton toRef="/contact-us">Contact Us</TwmButton>
    </Section>
  </Layout>
)

export default AboutYouPage

export const pageQuery = graphql`
  query {
    aboutYouImage: file(relativePath: { eq: "about-you.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
